
const browser = {
  versions: (function() {
    var u = navigator.userAgent
    let hasTargetMethod = window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.getToken
    return {
      //移动终端浏览器版本信息
      trident: u.indexOf('Trident') > -1 || u.indexOf('MSIE') > -1, //IE内核
      presto: u.indexOf('Presto') > -1, //opera内核
      webKit: u.indexOf('AppleWebKit') > -1, //苹果、谷歌内核
      gecko: u.indexOf('Gecko') > -1 && u.indexOf('KHTML') == -1, //火狐内核
      mobile: !!u.match(/AppleWebKit.*Mobile.*/), //是否为移动终端
      mac: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/), //ios终端
      android: u.indexOf('Android') > -1 || u.indexOf('Linux') > -1, //android终端或uc浏览器
      iPhone: u.indexOf('iPhone') > -1, //是否为iPhone或者QQHD浏览器
      iPad: u.indexOf('iPad') > -1, //是否iPad
      ios:
        !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) ||
        u.indexOf('iPhone') > -1 ||
        u.indexOf('iPad') > -1 ||
        u.indexOf('Macintosh') > -1,
      webApp: u.indexOf('Safari') == -1, //是否web应该程序，没有头部与底部
      lsApp: u.indexOf('Ys_Android') > -1 || u.indexOf('Ys_iPhone') > -1 || (hasTargetMethod && !u.includes(
        'MicroMessenger')), //是否链石应用
      lsAndroid: u.indexOf('Ys_Android') > -1, //链石应用安卓版
      lsIPhone: u.indexOf('Ys_iPhone') > -1 || (hasTargetMethod && !u.includes('MicroMessenger')), //链石应用iPhone版
      weixin: u.indexOf('MicroMessenger') > -1, //微信
      isPc: navigator.platform.indexOf('Win') === 0 ||
        navigator.platform.indexOf('Mac') === 0, // 是否pc端
    }
  })(),
  // 获取浏览器语言
  language: (navigator.browserLanguage || navigator.language).toLowerCase()
}

//返回原生app上一页
const navigateBack = () => {
  let appBack = getCurrentPages().length <= 1
  if (appBack && browser.versions.lsIPhone) {
    window.webkit && window.webkit.messageHandlers.navigateBack.postMessage({})
  } else if (appBack && browser.versions.lsAndroid) {
    control.navigateBack()
  }
}


export default {
  browser,
  navigateBack,
}