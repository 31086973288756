import Vue from 'vue'
import Router from 'vue-router'
const Index = r => require.ensure([], () => r(require('./index/index')), 'Index');
const Map = r => require.ensure([], () => r(require('./map/index')), 'Map');
const Search = r => require.ensure([], () => r(require('./search/index')), 'Search');
const Result = r => require.ensure([], () => r(require('./search/list')), 'Result');
const List = r => require.ensure([], () => r(require('./list/index')), 'List');
const Detail = r => require.ensure([], () => r(require('./list/detail')), 'Detail');

const originalReplace = Router.prototype.push
Router.prototype.push = function push(location) {
    return originalReplace.call(this, location).catch(err => err)
}

Vue.use(Router)
const router = new Router({
    mode: 'history',
    routes: [
        {
            path: '/search',
            name: 'Search',
            component: Search,
            meta: {
                auth: false
            }
        },
        {
            path: '/result',
            name: 'Result',
            component: Result,
            meta: {
                auth: false
            }
        },
        {
            path: '/list',
            name: 'List',
            component: List,
            meta: {
                auth: false
            }
        },
        {
            path: '/detail',
            name: 'Detail',
            component: Detail,
            meta: {
                auth: false
            }
        },
        {
            path: '/map',
            name: 'Map',
            component: Map,
            meta: {
                auth: true
            }
        },
        {
            path: '/',
            name: 'Index',
            component: Index,
            meta: {
                auth: false
            }
        }
    ]
})
// router.beforeEach((to, from, next) => {
//     let token = localStorage.getItem('userToken')
//     if(to.meta.auth) {
//         if(token) {
//             next()
//         } else {
//             next( {
//                 path: '/login'
//             })
//         }
//     } else {
//         next()
//     }
// })

export default router
