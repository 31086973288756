<template>
  <div id="app">
    <div class="nav-bar" v-if="showNavBar">
      <p class="title">智能制造地图</p>
      <div class="left-btn" @click="back">
        <van-icon name="arrow-left" size="24" color="#333"/>
      </div>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
import appUtils from '@/utils/appUtils'

let showNavBar = location.search.includes("showNavBar=1")

export default {
  name: 'App',
  data() {
    return {
      showNavBar,
    }
  },
  methods: {
    back() {
      console.log(this.$route.path)
      if (this.$route.path ==='/') {
        if (appUtils.browser.versions.lsIPhone) {
          window.webkit && window.webkit.messageHandlers.navigateBack.postMessage({})
        } else if (appUtils.browser.versions.lsAndroid) {
          window.control.navigateBack()
        }
      } else {
        this.$router.back()
      }
    }
  }
}
</script>

<style>
#app {
  font-family: PingFangSC-Regular, PingFang SC;
}

.nav-bar {
  width: 100%;
  height: 44px;
  display: flex;
  align-items: center;
  background-color: white;
  position: sticky;
  top: 0;
}

.nav-bar .title {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.left-btn {
  padding-left: 14px;
  position: relative;
  z-index: 9;
}

p {
  margin: 0;
  padding: 0;
  list-style: none;
}
</style>
